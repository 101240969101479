@charset "UTF-8";

/**
 * Components
 *
 * TABLE OF CONTENTS
 * 1. Components
 * 	  1.1 Article
 * 	  1.2 Author
 * 	  1.3 Avatar Item
 * 	  1.4 Browser
 * 	  1.5 Chat
 * 	  1.6 Chocolat
 * 	  1.7 Custom Tab
 * 	  1.8 DataTables
 * 	  1.9 Date Range Picker
 * 	  1.10 Dropzone
 * 	  1.11 Flag Icon
 * 	  1.12 Full Calendar
 * 	  1.13 Gallery
 * 	  1.14 Image Preview
 * 	  1.15 IonIcons
 * 	  1.16 jQVmap
 * 	  1.17 Profile
 * 	  1.18 Select2
 * 	  1.19 Selectric
 * 	  1.20 Slider
 * 	  1.21 Sparkline
 * 	  1.22 Statistics
 * 	  1.23 Summary
 * 	  1.24 Summernote
 * 	  1.25 Sweet Alert
 * 	  1.26 Tags Input
 * 	  1.27 Time Picker
 * 	  1.28 Toast
 * 	  1.29 User Item
 * 	  1.30 Weather
 * 	  1.31 Weather Icon
 * 	  1.32 PWStrength
 * 	  1.33 Product
 * 	  1.34 Ticket
 * 	  1.35 Owl Carousel
 * 	  1.36 Activities
 * 	  1.37 Invoices
 * 	  1.38 Empty States
 *    1.39 Pricing
 *    1.40 Hero
 *    1.41 Avatar
 *    1.42 Wizard
 */
/* 1.1 Article */
.article {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;
}

.article .article-header {
  height: 170px;
  position: relative;
  overflow: hidden;
}

.article .article-header .article-image {
  background-color: #fbfbfb;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.article .article-header .article-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.65) 98%, rgba(0, 0, 0, 0.65) 100%);
  padding: 10px;
}

.article .article-header .article-title h2 {
  font-size: 16px;
  line-height: 24px;
}

.article .article-header .article-title h2 a {
  font-weight: 700;
  text-decoration: none;
  color: #fff;
}

.article .article-details {
  background-color: #fff;
  padding: 20px;
  line-height: 24px;
}

.article .article-details .article-cta {
  text-align: center;
}

.article .article-header .article-badge {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.article .article-header .article-badge .article-badge-item {
  padding: 7px 15px;
  font-weight: 600;
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
}

.article .article-header .article-badge .article-badge-item .ion,
.article .article-header .article-badge .article-badge-item .fas,
.article .article-header .article-badge .article-badge-item .far,
.article .article-header .article-badge .article-badge-item .fab,
.article .article-header .article-badge .article-badge-item .fal {
  margin-right: 3px;
}

.article.article-style-b .article-details .article-title {
  margin-bottom: 10px;
}

.article.article-style-b .article-details .article-title h2 {
  line-height: 22px;
}

.article.article-style-b .article-details .article-title a {
  font-size: 16px;
  font-weight: 600;
}

.article.article-style-b .article-details p {
  color: #34395e;
}

.article.article-style-b .article-details .article-cta {
  text-align: right;
}

.article.article-style-c .article-header {
  height: 233px;
}

.article.article-style-c .article-details .article-category {
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
  color: #34395e;
}

.article.article-style-c .article-details .article-category a {
  font-size: 10px;
  color: #34395e;
  font-weight: 700;
}

.article.article-style-c .article-details .article-title {
  margin-bottom: 10px;
}

.article.article-style-c .article-details .article-title h2 {
  line-height: 22px;
}

.article.article-style-c .article-details .article-title a {
  font-size: 16px;
  font-weight: 600;
}

.article.article-style-c .article-details p {
  color: #34395e;
}

.article.article-style-c .article-user {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.article.article-style-c .article-user img {
  border-radius: 50%;
  float: left;
  width: 45px;
  margin-right: 15px;
}

.article.article-style-c .article-user .user-detail-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.article.article-style-c .article-user .user-detail-name a {
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .article .article-style-c .article-header {
    height: 225px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .article {
    margin-bottom: 40px;
  }

  .article .article-header {
    height: 195px !important;
  }

  .article.article-style-c .article-header {
    height: 155px;
  }
}

@media (max-width: 1024px) {
  .article.article-style-c .article-header {
    height: 216px;
  }

  .article .article-header {
    height: 155px;
  }
}

/* 1.2 Author */
.author-box .author-box-left {
  float: left;
  text-align: center;
  padding-left: 5px;
}

.author-box .author-box-left .btn {
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 30px;
}

.author-box .author-box-picture {
  width: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.author-box .author-box-details {
  margin-left: 135px;
}

.author-box .author-box-name {
  font-size: 18px;
}

.author-box .author-box-name a {
  font-weight: 600;
}

.author-box .author-box-job {
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 12px;
  color: #34395e;
}

.author-box .author-box-description {
  line-height: 26px;
  margin-top: 15px;
}

@media (max-width: 575.98px) {
  .author-box .author-box-left {
    float: none;
  }

  .author-box .author-box-details {
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
  }
}

/* 1.3 Avatar Item */
.avatar-item {
  position: relative;
  margin-bottom: 20px;
}

.avatar-item img {
  border-radius: 50%;
}

.avatar-item .avatar-badge {
  position: absolute;
  bottom: -5px;
  right: 0;
  background-color: #fff;
  color: #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  width: 25px;
  height: 25px;
}

/* 1.4 Browser */
.browser {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-size: 100%;
}

.browser.browser-chrome {
  background-image: url("../img/browsers/chrome.png");
}

.browser.browser-firefox {
  background-image: url("../img/browsers/firefox.png");
}

.browser.browser-internet-explorer {
  background-image: url("../img/browsers/internet-explorer.png");
}

.browser.browser-opera {
  background-image: url("../img/browsers/opera.png");
}

.browser.browser-safari {
  background-image: url("../img/browsers/safari.png");
}

/* 1.5 Chat */
.chat-box .chat-content {
  /* background-color: #e8e7e7 !important; */
  height: 330px;
  overflow: hidden;
  padding-top: 25px !important;
}

.chat-box .chat-content .chat-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 13px;
}

.chat-box .chat-content .chat-item.chat-right img {
  float: right;
}

.chat-box .chat-content .chat-item.chat-right .chat-details {
  margin-left: 0;
  margin-right: 70px;
  text-align: right;
}

.chat-box .chat-content .chat-item.chat-right .chat-details .chat-text {
  text-align: left;
  background-color: #6777ef;
  color: #fff;
}

.chat-box .chat-content .chat-item>img {
  float: left;
  width: 50px;
  border-radius: 50%;
}

.chat-box .chat-content .chat-item .chat-details {
  margin-left: 70px;
}

.chat-box .chat-content .chat-item .chat-details .chat-text {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 3px;
  width: auto;
  color: #000;
  display: inline-block;
  /* font-size: 12px; */
}

.chat-box .chat-content .chat-item .chat-details .chat-text img {
  max-width: 100%;
  margin-bottom: 10px;
}

.chat-box .chat-content .chat-item.chat-typing .chat-details .chat-text {
  background-image: url("../img/typing.svg");
  height: 40px;
  width: 60px;
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

.chat-box .chat-content .chat-item .chat-details .chat-time {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
  color: #444444;
}

.chat-box .chat-form {
  /* padding: 0; */
  position: relative;
}

.chat-box .chat-form .form-control {
  border: none;
  border-radius: 0px;
  padding: 15px;
  height: 50px;

  padding-right: 120px;
  padding-left: 60px;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  /* outline: auto; */
}

#chat_online_status {
  color: #716565;
}

.chat-box .chat-form .btn-arrow {
  /* padding: 0 !important; */
  width: 33px !important;
  height: 33px !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: -40% !important;
  right: 20px !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.chat-box .chat-form .btn {
  padding: 4px !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 74%;
  right: 20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.chat-box .chat-form .btn i {
  margin-left: 0;
}

.chat-box .chat-form .btn-file {
  padding: 8px !important;
  width: 40px;
  height: 40px;
  /* border-radius: 50%; */
  position: absolute;
  top: 74%;
  left: 50px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  z-index: 9999;
  margin: -13px;
  /* margin-left: 16px; */
}


.chat-theme-light .select2-search__field {
  width: 408px !important;
}

.chat-box .chat-form .btn-send-msg {

  z-index: 100;
  margin: -13px;
  margin-right: -18px;
}

.chat-box .chat-form .btn-file i {
  margin-left: 0;
}

/* 1.6 Chocolat */
.chocolat-wrapper {
  z-index: 890;
}

.chocolat-overlay {
  background-color: #000;
}

/* 1.7 Custom Tab */
[data-tab-group] {
  display: none;
}

[data-tab-group].active {
  display: block;
}

/* 1.8 DataTables */
table.dataTable {
  border-collapse: collapse !important;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid #ddd !important;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #ddd !important;
}

.dataTables_wrapper {
  padding: 0 !important;
  font-size: 13px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0 !important;
  margin: 0 !important;
  float: left;
}

div.dataTables_wrapper div.dataTables_processing {
  font-size: 0 !important;
  background-image: url("../img/spinner.svg") !important;
  background-color: #fff;
  background-size: 100%;
  width: 50px !important;
  height: 50px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  opacity: 1 !important;
}

/* 1.9 Date Range Picker */
.daterangepicker.dropdown-menu {
  width: auto;
}

.daterangepicker .input-mini {
  padding-left: 28px !important;
}

.daterangepicker .calendar th,
.daterangepicker .calendar td {
  padding: 5px;
  font-size: 12px;
}

.ranges li {
  color: #6777ef;
}

.ranges li:hover,
.ranges li.active {
  background-color: #6777ef;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #6777ef;
}

/* 1.10 Dropzone */
.dropzone {
  border: 2px dashed #6777ef;
  min-height: 240px;
  text-align: center;
}

.dropzone .dz-message {
  font-size: 24px;
  color: #34395e;
  margin: 3.4em;
}

.dropzone .dz-preview .dz-details {
  padding: 2.2em 1em;
}

.dropzone .dz-preview .dz-image {
  border-radius: 3px;
}

@media (max-width: 575.98px) {
  .dropzone .dz-message {
    margin: 2em;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .dropzone .dz-message {
    margin: 2.75em;
  }
}

/* 1.11 Flag Icon */
.flag-icon {
  width: 50px;
  height: 35px;
  display: inline-block;
  background-size: 100%;
}

.flag-icon.flag-icon-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

/* 1.12 Full Calendar */
.fc-toolbar h2 {
  font-size: 16px;
  margin-top: 4px;
}

.fc-view {
  border-color: #f2f2f2;
  color: #34395e !important;
  font-weight: 500;
  padding: 10px;
}

.fc-view>table {
  border-color: #f2f2f2;
}

.fc-view>table tr,
.fc-view>table td {
  border-color: #f2f2f2;
}

.fc-view>table th {
  border-color: #f2f2f2;
  color: #34395e !important;
  font-weight: 500;
  padding: 10px;
}

.fc-view-container>.fc-view {
  padding: 0;
}

.fc-view {
  color: #666;
  text-align: right;
}

.fc-view>table td {
  color: #666;
  text-align: right;
}

.fc-unthemed td.fc-today {
  background-color: #f2f2f2;
}

.fc button .fc-icon {
  top: -0.09em;
}

.fc-basic-view .fc-day-number,
.fc-basic-view .fc-week-number {
  padding: 10px;
}

.fc-day-grid-event .fc-content {
  padding: 5px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

tr:first-child>td>.fc-day-grid-event {
  margin-bottom: 10px;
}

.fc-state-default {
  border-radius: 3px;
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  box-shadow: none;
  text-transform: capitalize;
  font-weight: 500;
}

.fc button {
  height: auto;
  padding: 10px 15px;
  text-shadow: none;
  border-radius: 0;
}

.fc button.fc-state-active {
  background-color: #6777ef;
  color: #fff;
}

/* 1.13 Gallery */
.gallery {
  display: inline-block;
  width: 100%;
}

.gallery .gallery-item {
  float: left;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
}

.gallery .gallery-item:hover {
  opacity: 0.8;
}

.gallery .gallery-hide {
  display: none;
}

.gallery .gallery-more:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}

.gallery .gallery-more div {
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  position: relative;
  z-index: 2;
  color: #fff;
}

.gallery.gallery-md .gallery-item {
  width: 78px;
  height: 78px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.gallery.gallery-md .gallery-more div {
  line-height: 78px;
}

.gallery.gallery-fw .gallery-item {
  width: 100%;
  margin-bottom: 15px;
}

.gallery.gallery-fw .gallery-more div {
  font-size: 20px;
}

/* 1.14 Image Preview */
.image-preview,
#callback-preview {
  width: 250px;
  height: 250px;
  border: 2px dashed #ddd;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  color: #ecf0f1;
}

.image-preview input,
#callback-preview input {
  line-height: 200px;
  font-size: 200px;
  position: absolute;
  opacity: 0;
  z-index: 10;
}

.image-preview label,
#callback-preview label {
  position: absolute;
  z-index: 5;
  opacity: 0.8;
  cursor: pointer;
  background-color: #bdc3c7;
  width: 150px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  text-transform: uppercase;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}

.audio-preview {
  background: #ffffff;
  width: auto;
  padding: 20px;
  display: inline-block;
}

.audio-upload {
  cursor: pointer;
  background-color: #bdc3c7;
  color: #ecf0f1;
  padding: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

/* 1.15 IonIcons */
.ionicons {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.ionicons li {
  width: calc(100% / 8);
  font-size: 40px;
  padding: 40px 20px;
  list-style: none;
  text-align: center;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.ionicons li:hover {
  opacity: 0.8;
}

.ionicons li .icon-name {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  font-family: "Segoe UI";
  font-size: 12px;
  margin-top: 10px;
  line-height: 22px;
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 10px;
  display: none;
}

/* 1.16 jQVmap */
.jqvmap-circle {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: #fff;
  border: 3px solid #6777ef;
  border-radius: 50%;
}

.jqvmap-label {
  z-index: 889;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: auto;
  width: auto;
}

/* 1.17 Profile */
.profile-widget {
  margin-top: 35px;
}

.profile-widget .profile-widget-picture {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  float: left;
  width: 100px;
  margin: -35px -5px 0 30px;
  position: relative;
  z-index: 1;
}

.profile-widget .profile-widget-header {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.profile-widget .profile-widget-items {
  display: flex;
  position: relative;
}

.profile-widget .profile-widget-items:after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: -25px;
  right: 0;
  height: 1px;
  background-color: #f2f2f2;
}

.profile-widget .profile-widget-items .profile-widget-item {
  flex: 1;
  text-align: center;
  border-right: 1px solid #f2f2f2;
  padding: 10px 0;
}

.profile-widget .profile-widget-items .profile-widget-item:last-child {
  border-right: none;
}

.profile-widget .profile-widget-items .profile-widget-item .profile-widget-item-label {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #34395e;
}

.profile-widget .profile-widget-items .profile-widget-item .profile-widget-item-value {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.profile-widget .profile-widget-description {
  padding: 20px;
  line-height: 26px;
}

.profile-widget .profile-widget-description .profile-widget-name {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

@media (max-width: 575.98px) {
  .profile-widget .profile-widget-picture {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    margin: 40px 0;
    float: none;
  }

  .profile-widget .profile-widget-items .profile-widget-item {
    border-top: 1px solid #f2f2f2;
  }
}

/* 1.18 Select2 */
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
  box-shadow: none;
}

.select2-container .select2-selection--multiple,
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 42px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  outline: none;
  background-color: #fdfdff;
  border-color: #e4e6fc;
  border: solid 1px var(--border-color);
  border-radius: 10px;

}

.select2-dropdown {
  border-color: #e4e6fc !important;
}

.select2-container.select2-container--open .select2-selection--multiple {
  background-color: #fefeff;
  border-color: #95a0f4;
}

.select2-container.select2-container--focus .select2-selection--multiple,
.select2-container.select2-container--focus .select2-selection--single {
  background-color: #fefeff;
  border-color: #95a0f4;
}

.select2-container.select2-container--open .select2-selection--single {
  background-color: #fefeff;
  border-color: #95a0f4;
}

.select2-results__option {
  padding: 10px;
}

.select2-search--dropdown .select2-search__field {
  padding: 7px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  min-height: 42px;
  line-height: 42px;
  padding-left: 20px;
  padding-right: 20px;
}

.select2-container--default .select2-selection--multiple .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 40px;
  min-height: 42px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-left: 10px;
  padding-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 5px;
  color: #fff;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #6777ef;
  color: #fff;
}

.select2-results__option {
  padding-right: 10px 15px;
}

/* 1.19 Selectric */
.selectric {
  background-color: #fdfdff;
  border-color: #e4e6fc;
  min-height: 42px;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.selectric:hover {
  background-color: #fdfdff;
  border-color: #e4e6fc;
}

.selectric:focus {
  background-color: #fefeff;
  border-color: #95a0f4;
}

.selectric .label {
  font-size: 13px;
  background-color: transparent;
  line-height: 44px;
  min-height: 42px;
}

.selectric .button {
  background-color: transparent;
  line-height: 44px;
  min-height: 42px;
}

.selectric-open .selectric {
  border-color: #6777ef;
}

.selectric-above .selectric-items,
.selectric-below .selectric-items {
  margin-bottom: 10px;
}

.selectric-items {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  background-color: #fff;
  border: none;
}

.selectric-items li {
  font-size: 13px;
  padding: 10px 15px;
}

.selectric-items li:hover {
  background-color: #f2f2f2;
}

.selectric-items li.selected,
.selectric-items li.highlighted {
  background-color: #6777ef;
  color: #fff;
}

/* 1.20 Slider */
.slider .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  left: 35px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 34px;
  opacity: 0.3;
}

.slider .owl-nav [class*=owl-]:hover {
  background-color: #000;
}

.slider .owl-nav .owl-next {
  right: 0;
  left: initial;
}

.slider:hover .owl-nav [class*=owl-] {
  opacity: 1;
}

.slider .slider-caption {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 10px;
}

.slider .slider-caption .slider-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.slider .slider-caption .slider-description {
  line-height: 26px;
  opacity: 0.8;
}

/* 1.21 Sparkline */
.jqstooltip {
  box-sizing: content-box;
}

.sparkline-bar,
.sparkline-line,
.sparkline-inline {
  width: 100%;
}

.sparkline-bar canvas,
.sparkline-line canvas,
.sparkline-inline canvas {
  width: 100% !important;
}

/* 1.22 Statistics */
.statistic-details {
  display: flex;
  flex-wrap: wrap;
}

.statistic-details .statistic-details-item {
  flex: 1;
  padding: 17px 10px;
  text-align: center;
}

.statistic-details .statistic-details-item .detail-chart {
  margin-bottom: 10px;
  padding: 0 20px;
}

.statistic-details .statistic-details-item .detail-name {
  font-size: 12px;
  margin-top: 5px;
  color: #34395e;
  letter-spacing: 0.3px;
}

.statistic-details .statistic-details-item .detail-value {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .statistic-details {
    flex-wrap: wrap;
  }

  .statistic-details .statistic-details-item {
    flex: initial;
    width: 50%;
  }
}

/* 1.23 Summary */
.summary {
  display: inline-block;
  width: 100%;
}

.summary .summary-info {
  background-color: #eaf2f4;
  padding: 50px 0;
  text-align: center;
  border-radius: 3px;
}

.summary .summary-info h4 {
  font-weight: 600;
}

.summary .summary-item {
  margin-top: 20px;
}

.summary .summary-item h6 {
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 20px;
}

/* 1.24 Summernote */
.note-editor.note-frame {
  border-radius: 3px;
  border: 1px solid #ededed;
  box-shadow: none;
}

.note-toolbar {
  padding: 0 0 5px 5px !important;
  position: relative !important;
}

.note-toolbar.card-header {
  height: auto;
  display: block;
  min-height: auto;
}

.note-toolbar .note-btn {
  font-size: 12px;
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
}

/* 1.25 Sweet Alert */
.swal-button {
  border-radius: 3px;
  font-size: 16px;
}

.swal-button:focus {
  box-shadow: none;
}

.swal-button.swal-button--confirm {
  box-shadow: 0 2px 6px #acb5f6;
  background-color: #6777ef;
}

.swal-button.swal-button--confirm:focus {
  opacity: 0.8;
}

.swal-footer {
  text-align: center;
}

.swal-text {
  text-align: center;
  line-height: 24px;
  font-weight: 500;
}

/* 1.26 Tags Input */
.bootstrap-tagsinput {
  background-color: #fdfdff;
  border-color: #e4e6fc;
  display: block;
  height: 46px;
  box-shadow: none;
  overflow: auto;
}

.bootstrap-tagsinput input {
  height: 100%;
  padding: 0 8px;
}

.bootstrap-tagsinput .tag {
  background-color: #6777ef;
  border-radius: 3px;
  padding: 5px 10px;
}

.bootstrap-tagsinput .tag:first-child {
  margin-left: 5px;
}

.bootstrap-tagsinput:focus {
  background-color: #fefeff;
  border-color: #95a0f4;
}

/* 1.27 Time Picker */
.bootstrap-timepicker-widget table td a span {
  margin-left: 0 !important;
}

/* 1.28 Toast */
#toast-container>div {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  padding: 20px 20px 20px 50px;
  opacity: 1;
}

#toast-container>.toast {
  background-image: none !important;
}

#toast-container>.toast:before {
  position: absolute;
  left: 17px;
  top: 25px;
  font-family: "Ionicons";
  font-size: 24px;
  line-height: 18px;
  color: #fff;
}

#toast-container>.toast-warning:before {
  content: "";
}

#toast-container>.toast-error:before {
  content: "";
}

#toast-container>.toast-info:before {
  content: "";
  color: #000;
}

#toast-container>.toast-success:before {
  content: "";
}

.toast.toast-error {
  background-color: #fc544b;
}

.toast.toast-warning {
  background-color: #ffa426;
}

.toast.toast-success {
  background-color: #63ed7a;
}

.toast.toast-info {
  background-color: #fff;
}

.toast.toast-info .toast-title {
  color: #000;
}

.toast.toast-info .toast-message {
  color: #000;
  margin-top: 5px;
}

/* 1.29 User Item */
.user-item {
  text-align: center;
}

.user-item img {
  border-radius: 50%;
  padding-left: 20px;
  padding-right: 20px;
}

.user-item .user-details {
  margin-top: 10px;
}

.user-item .user-details .user-name {
  font-weight: 600;
  color: #191d21;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-item .user-details .user-cta {
  margin-top: 10px;
}

.user-item .user-details .user-cta .btn {
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 30px;
}

@media (max-width: 575.98px) {

  .user-progress .media,
  .user-details .media {
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  .user-progress .media img,
  .user-details .media img {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  .user-progress .media .media-body,
  .user-details .media .media-body {
    width: 100%;
  }

  .user-progress .media .media-items,
  .user-details .media .media-items {
    margin: 20px 0;
    width: 100%;
  }

  .user-progress .list-unstyled-noborder li:last-child,
  .user-details .list-unstyled-noborder li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .user-progress .media .media-progressbar {
    margin-top: 10px;
  }

  .user-progress .media .media-cta {
    margin-top: 20px;
    margin-left: 0;
  }
}

/* 1.30 Weather */
.weather .weather-icon {
  float: left;
  width: 150px;
  text-align: center;
  line-height: 40px;
}

.weather .weather-icon span {
  font-size: 60px;
  margin-top: 30px;
}

.weather .weather-desc {
  margin-left: 160px;
}

.weather .weather-desc h4 {
  font-size: 70px;
  font-weight: 200;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 5px;
  line-height: 56px;
}

.weather .weather-desc .weather-text {
  font-size: 12px;
  color: #34395e;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 10px;
}

.weather .weather-desc ul {
  margin: 15px 0 13px 0;
  padding: 0;
}

.weather ul li {
  display: inline-block;
  margin-right: 10px;
  padding: 10px;
  line-height: 1;
  border-radius: 3px;
  border: 2px solid #6777ef;
  font-size: 10px;
  font-weight: 500;
  color: #6777ef;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

@media (max-width: 575.98px) {
  .weather {
    text-align: center;
  }

  .weather .weather-icon {
    float: none;
    width: auto;
  }

  .weather .weather-icon span {
    margin-top: 20px;
  }

  .weather .weather-desc {
    margin-left: 0;
  }
}

/* 1.31 Weather Icon */
.icon-wrap {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 25px;
  width: calc(100% / 4);
}

.icon-wrap .icon {
  float: left;
  width: 40px;
  font-family: "weathericons";
  font-size: 20px;
}

.icon-wrap .icon_unicode {
  width: 100%;
  padding-left: 45px;
  color: #34395e;
}

.new-icons ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.new-icons ul li {
  padding: 10px;
}

.icon-wrap .icon,
.new-icons ul li .wi {
  font-size: 24px;
  margin-right: 15px;
  width: 30px;
  text-align: center;
}

/* 1.32 PWStrength */
.pwindicator {
  margin-top: 4px;
  width: 150px;
}

.pwindicator .bar {
  height: 2px;
}

.pw-very-weak .bar {
  background: #d00;
  width: 30px;
}

.pw-very-weak .label {
  color: #d00;
}

.pw-weak .bar {
  background: #d00;
  width: 60px;
}

.pw-weak .label {
  color: #d00;
}

.pw-mediocre .bar {
  background: #f3f01a;
  width: 90px;
}

.pw-mediocre .label {
  color: #f3f01a;
}

.pw-strong .bar {
  background: #f3b31a;
  width: 120px;
}

.pw-strong .label {
  color: #f3b31a;
}

.pw-very-strong .bar {
  background: #0d0;
  width: 150px;
}

.pw-very-strong .label {
  color: #0d0;
}

/* 1.33 Product */
.product-item {
  text-align: center;
}

.product-item .product-image {
  display: inline-block;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.product-item .product-name {
  color: #34395e;
  font-weight: 700;
  margin-bottom: 3px;
}

.product-item .product-review {
  color: #ffa426;
  margin-bottom: 3px;
}

.product-item .product-cta {
  margin-top: 5px;
}

.product-item .product-cta a {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

/* 1.34 Ticket */
.tickets-list .ticket-item {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #f9f9f9;
}

.tickets-list .ticket-item.ticket-more {
  padding: 15px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
}

.tickets-list .ticket-item .ticket-title h4 {
  font-size: 16px;
  font-weight: 700;
}

.tickets-list .ticket-item .ticket-info {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #34395e;
  letter-spacing: 0.5px;
}

.tickets-list .ticket-item .ticket-info .bullet {
  margin: 0 10px;
}

.tickets {
  display: flex;
}

.tickets .ticket-items {
  width: 30%;
  padding-right: 30px;
}

.tickets .ticket-items .ticket-item {
  display: inline-block;
  width: 100%;
  padding: 25px 15px;
  border-bottom: 1px solid #f9f9f9;
  cursor: pointer;
  transition: all 0.5s;
}

.tickets .ticket-items .ticket-item:hover {
  background-color: rgba(63, 82, 227, 0.03);
}

.tickets .ticket-items .ticket-item:hover .ticket-title {
  color: #6777ef;
}

.tickets .ticket-items .ticket-item.active {
  box-shadow: 0 2px 6px #acb5f6;
  border-radius: 3px;
  background-color: #6777ef;
  border-bottom: none;
}

.tickets .ticket-items .ticket-item.active .ticket-title,
.tickets .ticket-items .ticket-item.active .ticket-desc {
  color: #fff !important;
}

.tickets .ticket-items .ticket-item .ticket-title h4 {
  font-size: 13px;
  letter-spacing: 0.3px;
}

.tickets .ticket-items .ticket-item .ticket-title h4 .badge {
  padding: 7px 10px;
  margin-left: 5px;
}

.tickets .ticket-items .ticket-item .ticket-desc {
  display: flex;
  font-size: 11px;
  font-weight: 500;
  color: #34395e;
  letter-spacing: 0.5px;
}

.tickets .ticket-items .ticket-item .ticket-desc .bullet {
  margin: 0 10px;
}

.tickets .ticket-content {
  width: 70%;
}

.tickets .ticket-content .ticket-header {
  display: flex;
}

.tickets .ticket-content .ticket-header .ticket-sender-picture {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 20px;
}

.tickets .ticket-content .ticket-header .ticket-sender-picture img {
  width: 100%;
}

.tickets .ticket-content .ticket-header .ticket-detail .ticket-title h4 {
  font-size: 18px;
  font-weight: 700;
}

.tickets .ticket-content .ticket-header .ticket-detail .ticket-info {
  display: flex;
  letter-spacing: 0.3px;
  font-size: 12px;
  font-weight: 500;
  color: #34395e;
}

.tickets .ticket-content .ticket-header .ticket-detail .ticket-info .bullet {
  margin: 0 10px;
}

.tickets .ticket-divider {
  height: 1px;
  width: 100%;
  display: inline-block;
  background-color: #f2f2f2;
}

.tickets .ticket-description {
  color: #34395e;
  font-weight: 500;
  margin-top: 30px;
  line-height: 28px;
}

.tickets .ticket-description p {
  margin-bottom: 20px;
}

.tickets .ticket-description .ticket-form {
  margin-top: 40px;
}

.tickets .ticket-description .ticket-form .note-editable {
  color: #34395e;
  font-weight: 500;
}

.tickets .ticket-description .ticket-form .note-editable p {
  margin-bottom: 5px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .tickets {
    display: inline-block;
  }

  .tickets .ticket-items {
    width: 100%;
    margin-bottom: 30px;
    padding: 0;
    display: none;
  }

  .tickets .ticket-content {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .tickets {
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .tickets .ticket-items {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    margin-bottom: 15px;
    padding: 15px;
    overflow: auto;
  }

  .tickets .ticket-items .ticket-item {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .tickets .ticket-content {
    margin: 15px;
    width: 100%;
  }
}

/* 1.35 Owl Carousel */
.owl-theme .owl-item {
  padding: 10px 0;
}

.owl-theme .owl-dots {
  margin-top: 20px !important;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #6777ef;
}

/* 1.36 Activities */
.activities {
  display: flex;
  flex-wrap: wrap;
}

.activities .activity {
  width: 100%;
  display: flex;
  position: relative;
}

.activities .activity:before {
  content: " ";
  position: absolute;
  left: 25px;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #6777ef;
}

.activities .activity:last-child:before {
  display: none;
}

.activities .activity .activity-icon {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
  margin-right: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  text-align: center;
  z-index: 1;
}

.activities .activity .activity-detail {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  position: relative;
  padding: 15px;
}

.activities .activity .activity-detail:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  position: absolute;
  left: -8px;
  color: #fff;
}

.activities .activity .activity-detail h4 {
  font-size: 18px;
  color: #191d21;
}

.activities .activity .activity-detail p {
  margin-bottom: 0;
}

/* 1.37 Activities */
.invoice {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  padding: 40px;
}

.invoice .invoice-title .invoice-number {
  float: right;
  font-size: 20px;
  font-weight: 700;
  /* margin-top: -45px; */
}

.invoice hr {
  margin-top: 40px;
  margin-bottom: 40px;
  border-top-color: #f9f9f9;
}

.invoice .invoice-detail-item {
  margin-bottom: 15px;
}

.invoice .invoice-detail-item .invoice-detail-name {
  letter-spacing: 0.3px;
  color: #98a6ad;
  margin-bottom: 4px;
}

.invoice .invoice-detail-item .invoice-detail-value {
  font-size: 18px;
  color: #34395e;
  font-weight: 700;
}

.invoice .invoice-detail-item .invoice-detail-value.invoice-detail-value-lg {
  font-size: 24px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .table-invoice table {
    min-width: 800px;
  }
}

/* 1.38 Empty States */
.empty-state {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
}

.empty-state .empty-state-icon {
  position: relative;
  background-color: #6777ef;
  width: 80px;
  height: 80px;
  line-height: 100px;
  border-radius: 5px;
}

.empty-state .empty-state-icon i {
  font-size: 40px;
  color: #fff;
  position: relative;
  z-index: 1;
}

.empty-state h2 {
  font-size: 20px;
  margin-top: 30px;
}

.empty-state p {
  font-size: 16px;
}

/* 1.39 Pricing */
.pricing {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  text-align: center;
}

.pricing.pricing-highlight .pricing-title {
  background-color: #6777ef;
  color: #fff;
}

.pricing.pricing-highlight .pricing-cta a {
  background-color: #6777ef;
  color: #fff;
}

.pricing.pricing-highlight .pricing-cta a:hover {
  background-color: #394eea !important;
}

.pricing .pricing-padding {
  padding: 40px;
}

.pricing .pricing-title {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  background-color: #f3f6f8;
  color: #6777ef;
  border-radius: 0 0 3px 3px;
  display: inline-block;
  padding: 5px 15px;
}

.pricing .pricing-price {
  margin-bottom: 45px;
}

.pricing .pricing-price div:first-child {
  font-weight: 600;
  font-size: 50px;
}

.pricing .pricing-details {
  text-align: left;
  display: inline-block;
}

.pricing .pricing-details .pricing-item {
  display: flex;
  margin-bottom: 15px;
}

.pricing .pricing-details .pricing-item .pricing-item-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  background-color: #63ed7a;
  color: #fff;
  margin-right: 10px;
}

.pricing .pricing-details .pricing-item .pricing-item-icon i {
  font-size: 11px;
}

.pricing .pricing-cta {
  margin-top: 20px;
}

.pricing .pricing-cta a {
  display: block;
  padding: 20px 40px;
  background-color: #f3f6f8;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 0 0 3px 3px;
}

.pricing .pricing-cta a .fas,
.pricing .pricing-cta a .far,
.pricing .pricing-cta a .fab,
.pricing .pricing-cta a .fal,
.pricing .pricing-cta a .ion {
  margin-left: 5px;
}

.pricing .pricing-cta a:hover {
  background-color: #e3eaef;
}

/* 1.40 Hero */
.hero {
  border-radius: 3px;
  padding: 55px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.hero.hero-bg-image {
  background-position: center;
  background-size: cover;
}

.hero.hero-bg-image:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: transparent;
  z-index: 0;
  border-radius: 3px;
}

.hero.hero-bg-image.hero-bg-parallax {
  background-attachment: fixed;
}

.hero .hero-inner {
  position: relative;
  z-index: 1;
}

.hero h2 {
  font-size: 24px;
}

.hero p {
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 0.3px;
}

/* 1.41 Avatar */
/**
 * Thanks to Spectre.css
 */
.avatar {
  background: #6777ef;
  border-radius: 50%;
  color: #e3eaef;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  position: relative;
  vertical-align: middle;
  line-height: 1.28;
  height: 45px;
  width: 45px;
}

.avatar.avatar-xs {
  font-size: 6px;
  height: 15px;
  width: 15px;
}

.avatar.avatar-sm {
  font-size: 10px;
  height: 26px;
  width: 26px;
}

.avatar.avatar-lg {
  font-size: 23px;
  height: 60px;
  width: 60px;
}

.avatar.avatar-xl {
  font-size: 30px;
  height: 75px;
  width: 75px;
}

.avatar img {
  border-radius: 50%;
  height: 45px !important;
  position: relative;
  width: 100%;
  z-index: 1;
}

.avatar .avatar-icon {
  background: #fff;
  bottom: 14.64%;
  height: 50%;
  padding: 0.1rem;
  position: absolute;
  right: 14.64%;
  transform: translate(50%, 50%);
  width: 50%;
  z-index: 2;
}

.avatar .avatar-presence {
  background: #fff;
  bottom: 14.64%;
  height: 50%;
  padding: 0.1rem;
  position: absolute;
  right: 14.64%;
  transform: translate(50%, 50%);
  width: 50%;
  z-index: 2;
  background: #bcc3ce;
  border-radius: 50%;
  box-shadow: 0 0 0 0.1rem #fff;
  height: 0.5em;
  width: 0.5em;
}

.avatar .avatar-presence.online {
  background: #63ed7a;
}

.avatar .avatar-presence.busy {
  background: #fc544b;
}

.avatar .avatar-presence.away {
  background: #ffa426;
}

.avatar[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* 1.42 Wizard */
.wizard-steps {
  display: flex;
  margin: 0 -10px;
  margin-bottom: 60px;
  counter-reset: wizard-counter;
}

.wizard-steps .wizard-step {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  padding: 30px;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 10px;
}

.wizard-steps .wizard-step:before {
  counter-increment: wizard-counter;
  content: counter(wizard-counter);
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  line-height: 21px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 50%;
  background-color: #e3eaef;
}

.wizard-steps .wizard-step.wizard-step-active {
  box-shadow: 0 2px 6px #acb5f6;
  background-color: #6777ef;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-active:before {
  background-color: #6777ef;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-success {
  background-color: #63ed7a;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-success:before {
  background-color: #63ed7a;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-danger {
  background-color: #fc544b;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-danger:before {
  background-color: #fc544b;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-warning {
  background-color: #ffa426;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-warning:before {
  background-color: #ffa426;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-info {
  background-color: #3abaf4;
  color: #fff;
}

.wizard-steps .wizard-step.wizard-step-info:before {
  background-color: #3abaf4;
  color: #fff;
}

.wizard-steps .wizard-step .wizard-step-icon .fas,
.wizard-steps .wizard-step .wizard-step-icon .far,
.wizard-steps .wizard-step .wizard-step-icon .fab,
.wizard-steps .wizard-step .wizard-step-icon .fal,
.wizard-steps .wizard-step .wizard-step-icon .ion {
  font-size: 34px;
  margin-bottom: 15px;
}

.wizard-steps .wizard-step .wizard-step-label {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

@media (max-width: 575.98px) {
  .wizard-steps {
    display: block;
  }

  .wizard-steps .wizard-step {
    margin-bottom: 50px;
  }
}

.clr-picker {
  display: none;
  flex-wrap: wrap;
  position: absolute;
  width: 200px;
  z-index: 1000;
  border-radius: 10px;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, .05), 0 5px 20px rgba(0, 0, 0, .1);
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.clr-picker.clr-open {
  display: flex;
}

.clr-picker[data-alpha="false"] .clr-alpha {
  display: none;
}

.clr-gradient {
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  border-radius: 3px 3px 0 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000), linear-gradient(90deg, #fff, currentColor);
  cursor: pointer;
}

.clr-marker {
  position: absolute;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: currentColor;
  cursor: pointer;
}

.clr-picker input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
}

.clr-picker input[type="range"]::-webkit-slider-thumb {
  width: 8px;
  height: 8px;
  -webkit-appearance: none;
}

.clr-picker input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  border: 0;
}

.clr-picker input[type="range"]::-moz-range-thumb {
  width: 8px;
  height: 8px;
  border: 0;
}

.clr-hue {
  background-image: linear-gradient(to right, #f00 0%, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, #f00 100%);
}

.clr-hue,
.clr-alpha {
  position: relative;
  width: calc(100% - 40px);
  height: 8px;
  margin: 5px 20px;
  border-radius: 4px;
}

.clr-alpha span {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), currentColor);
}

.clr-hue input,
.clr-alpha input {
  position: absolute;
  width: calc(100% + 16px);
  height: 16px;
  left: -8px;
  top: -4px;
  margin: 0;
  background-color: transparent;
  opacity: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
}

.clr-hue div,
.clr-alpha div {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  margin-left: -8px;
  transform: translateY(-50%);
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: currentColor;
  box-shadow: 0 0 1px #888;
  pointer-events: none;
}

.clr-alpha div:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: currentColor;
}

.clr-format {
  display: none;
  order: 1;
  width: calc(100% - 40px);
  margin: 0 20px 20px;
}

.clr-segmented {
  display: flex;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-sizing: border-box;
  color: #999;
  font-size: 12px;
}

.clr-segmented input,
.clr-segmented legend {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.clr-segmented label {
  flex-grow: 1;
  padding: 4px 0;
  text-align: center;
  cursor: pointer;
}

.clr-segmented label:first-of-type {
  border-radius: 10px 0 0 10px;
}

.clr-segmented label:last-of-type {
  border-radius: 0 10px 10px 0;
}

.clr-segmented input:checked+label {
  color: #fff;
  background-color: #666;
}

.clr-swatches {
  order: 2;
  width: calc(100% - 32px);
  margin: 0 16px;
}

.clr-swatches div {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
  justify-content: center;
}

.clr-swatches button {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 4px 6px 4px;
  border: 0;
  border-radius: 50%;
  color: inherit;
  text-indent: -1000px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.clr-swatches button:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: currentColor;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
}

input.clr-color {
  order: 1;
  width: calc(100% - 80px);
  height: 32px;
  margin: 15px 20px 20px 0;
  padding: 0 10px;
  border: 1px solid #ddd;
  border-radius: 16px;
  color: #444;
  background-color: #fff;
  font-family: sans-serif;
  font-size: 14px;
  text-align: center;
  box-shadow: none;
}

input.clr-color:focus {
  outline: none;
  border: 1px solid #1e90ff;
}

.clr-clear {
  display: none;
  order: 2;
  height: 24px;
  margin: 0 20px 20px auto;
  padding: 0 20px;
  border: 0;
  border-radius: 12px;
  color: #fff;
  background-color: #666;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.clr-preview {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 15px 0 20px 20px;
  border: 0;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.clr-preview:before,
.clr-preview:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border: 1px solid #fff;
  border-radius: 50%;
}

.clr-preview:after {
  border: 0;
  background-color: currentColor;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
}

.clr-marker,
.clr-hue div,
.clr-alpha div,
.clr-color {
  box-sizing: border-box;
}

.clr-field {
  display: inline-block;
  position: relative;
  color: transparent;
}

.clr-field button {
  position: absolute;
  width: 30px;
  height: 100%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  color: inherit;
  text-indent: -1000px;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
}

.clr-field button:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: currentColor;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, .5);
}

.clr-alpha,
.clr-alpha div,
.clr-swatches button,
.clr-preview:before,
.clr-field button {
  background-image: repeating-linear-gradient(45deg, #aaa 25%, transparent 25%, transparent 75%, #aaa 75%, #aaa), repeating-linear-gradient(45deg, #aaa 25%, #fff 25%, #fff 75%, #aaa 75%, #aaa);
  background-position: 0 0, 4px 4px;
  background-size: 8px 8px;
}

.clr-marker:focus {
  outline: none;
}

.clr-keyboard-nav .clr-marker:focus,
.clr-keyboard-nav .clr-hue input:focus+div,
.clr-keyboard-nav .clr-alpha input:focus+div,
.clr-keyboard-nav .clr-segmented input:focus+label {
  outline: none;
  box-shadow: 0 0 0 2px #1e90ff, 0 0 2px 2px #fff;
}

/** Dark theme **/

.clr-dark {
  background-color: #444;
}

.clr-dark .clr-segmented {
  border-color: #777;
}

.clr-dark .clr-swatches button:after {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .3);
}

.clr-dark input.clr-color {
  color: #fff;
  border-color: #777;
  background-color: #555;
}

.clr-dark input.clr-color:focus {
  border-color: #1e90ff;
}

.clr-dark .clr-preview:after {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .5);
}

/** Polaroid theme **/

.clr-picker.clr-polaroid {
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1), 0 5px 30px rgba(0, 0, 0, .2);
}

.clr-picker.clr-polaroid:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 10px;
  left: 20px;
  top: -10px;
  border: solid transparent;
  border-width: 0 8px 10px 8px;
  border-bottom-color: currentColor;
  box-sizing: border-box;
  color: #fff;
  filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, .1));
  pointer-events: none;
}

.clr-picker.clr-polaroid.clr-dark:before {
  color: #444;
}

.clr-picker.clr-polaroid.clr-left:before {
  left: auto;
  right: 20px;
}

.clr-picker.clr-polaroid.clr-top:before {
  top: auto;
  bottom: -10px;
  transform: rotateZ(180deg);
}

.clr-polaroid .clr-gradient {
  width: calc(100% - 20px);
  height: 120px;
  margin: 10px;
  border-radius: 3px;
}

.clr-polaroid .clr-hue,
.clr-polaroid .clr-alpha {
  width: calc(100% - 30px);
  height: 10px;
  margin: 6px 15px;
  border-radius: 5px;
}

.clr-polaroid .clr-hue div,
.clr-polaroid .clr-alpha div {
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}

.clr-polaroid .clr-format {
  width: calc(100% - 20px);
  margin: 0 10px 15px;
}

.clr-polaroid .clr-swatches {
  width: calc(100% - 12px);
  margin: 0 6px;
}

.clr-polaroid .clr-swatches div {
  padding-bottom: 10px;
}

.clr-polaroid .clr-swatches button {
  width: 22px;
  height: 22px;
}

.clr-polaroid input.clr-color {
  width: calc(100% - 60px);
  margin: 10px 10px 15px 0;
}

.clr-polaroid .clr-clear {
  margin: 0 10px 15px auto;
}

.clr-polaroid .clr-preview {
  margin: 10px 0 15px 10px;
}

/** Large theme **/

.clr-picker.clr-large {
  width: 275px;
}

.clr-large .clr-gradient {
  height: 150px;
}

.clr-large .clr-swatches button {
  width: 22px;
  height: 22px;
}

/*# source-MappingURL=components.css.map */